import * as ReactDOM from "react-dom/client";
import { React, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LaunchIcon from "@mui/icons-material/Launch";

// import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";

// import VestmapLoader from "./VestmapLoader";
import Divider from "@mui/material/Divider";

import UserCredit from "./mongodb/UserCredit";
import VestmapList from "./VestmapList";
import VestmapLoaderList from "./VestmapLoaderList";

const Content = (props) => {
  const [address, setAddress] = useState("");
  const [bedroom, setBedroom] = useState(2);

  const userCreditLimit = (credit) => {
    return credit;
  };

  const setAddressChange = (event) => {
    console.log("Content address value", event.target.value);
    setAddress(event.target.value);
  };
  const setBedroomChange = (event) => {
    setBedroom(event.target.value);
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBDFFIy70N3lm1AHZZBTZxclX2V41jiubg",
    // options: { types: ["geocode", "address", "establishment", "(regions)", "(cities)"], componentRestrictions: { country: "us" } },
    options: { types: ["address"], componentRestrictions: { country: "us" } },
    onPlaceSelected: (place) => {
      setAddress(place.formatted_address);
    },
  });

  const RecordCreate = () => {
    if (props.activeCredit <= 0) {
      alert("Please recharge your account");
      return;
    }

    setAddress("");

    console.log("Content creatRecords userid", props.userId);
    console.log("Content creatRecords address", address);
    console.log("Content creatRecords bedroom", bedroom);

    // Add Vestmap Loader MangoDB
    var url = process.env.REACT_APP_NODE_URL + `/vestmap_loader/add?userid=${props.userId}&address=${address}&bedroom=${bedroom}`;
    console.log("Content Add Vestmap loader MangoDB url", url);
    fetch(url)
      .then((response) => response.json())
      .then((vestmap_loader_data) => {
        console.log(
          "Content Add Vestmap Loader MangoDB data",
          vestmap_loader_data
        );
        ReactDOM.createRoot(
          document.getElementById("userCreditRefresh")
        ).render(
          <>
            Searches Remaining: <UserCredit userCreditLimit={userCreditLimit} />
            /100
          </>
        );
        ReactDOM.createRoot(
          document.getElementById("DashboardVestmapsGrid")
        ).render(
          <>
            <VestmapLoaderList userId={props.userId} />
            <VestmapList userId={props.userId} />
          </>
        );

        // Add Vestmap MangoDB
        var url = process.env.REACT_APP_NODE_URL + `/request-address?address=${address}&bedroom=${bedroom}&api=iggouwBhfAl8HuhoRjx&userid=${props.userId}&vestmap_loader=${vestmap_loader_data.insertedId}`;
        console.log("Content Add Vestmap MangoDB url", url);
        fetch(url)
          .then((response) => {
            response.json();
          })
          .then((data) => {
            // window.location.reload(false);
            console.log("Content Add Vestmap MangoDB data", data);
            ReactDOM.createRoot(
              document.getElementById("DashboardVestmapsGrid")
            ).render(
              <>
                <VestmapLoaderList userId={props.userId} />
                <VestmapList userId={props.userId} />
              </>
            );
          })
          .catch((error) =>
            console.log("Content Add Vestmap MangoDB error", error)
          );
        // Add Vestmap MangoDB
      })
      .catch((error) => {
        console.log("Content Add Vestmap Loader MangoDB error", error);
      });
    // Add Vestmap Loader MangoDB

    return;
  };

  return (
    <div className="DashboardContent">
      <div className="DashboardTitle">
        <div id="userCreditRefresh" className="DashboardTitle--txt">
          Searches Remaining: <UserCredit userCreditLimit={userCreditLimit} />
          /100
        </div>
        <Button
          endIcon={<LaunchIcon />}
          variant="contained"
          color="success"
          size="medium"
          sx={{
            "&:hover": { bgcolor: "#39585c" },
            bgcolor: "#39585c",
            m: "auto",
            ml: "15px",
          }}
        >
          Get More Searches{" "}
        </Button>
      </div>
      <div className="DashboardFrom">
        <Grid container spacing={2} sx={{ mb: "10px" }}>
          <Grid xs={12} md={8}>
            <TextField
              inputRef={ref}
              value={address}
              onChange={setAddressChange}
              label="Enter a Valid Stress Address"
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <Select
              value={bedroom}
              onChange={setBedroomChange}
              sx={{ width: "100%" }}
              labelId="bedrooms"
              label="Select Bedrooms"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container>
          <Button
            onClick={RecordCreate}
            className="btn"
            variant="contained"
            padding="50px"
            size="large"
            sx={{
              "&:hover": { bgcolor: "#659f8f" },
              bgcolor: "#659f8f",
              width: "100%",
            }}
          >
            VestMap It
          </Button>
        </Grid>
      </div>
      <div>
        <div className="DashboardVestmap">
          <div className="DashboardVestmapTitle">
            <h2> My VestMaps</h2>
            <Divider sx={{ borderColor: "#dbdad9", mb: "25px" }} />
          </div>
          <div className="DashboardVestmaps">
            <Grid
              id="DashboardVestmapsGrid"
              className="DashboardVestmapsGrid"
              container
              spacing={4}
            >
              <VestmapLoaderList userId={props.userId} />

              <VestmapList userId={props.userId} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
