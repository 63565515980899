import {useState, useEffect} from 'react';

export default function UserCredit({userCreditLimit}) {

    const [credit, setCredit] = useState(0);

    useEffect(() => {

        async function UserCreditId() {

            const login_user_email = localStorage.getItem( "login_user_email" );
            console.log("UserCredit login_user_email", login_user_email);
    
            let url = process.env.REACT_APP_NODE_URL + '/user_data/search?email=' + login_user_email;
            console.log("UserCredit user_data_search url", url);

            fetch( url )
            .then((response) => response.json())
            .then((data) => {
                setCredit(data);
                console.log("UserCredit ID", data[0]._id);
                console.log("UserCredit data", data);
            })
            .catch((error) => console.log("UserCredit Error", error) )    
    
        }

        UserCreditId();

    }, []);

    if (credit) return userCreditLimit(credit[0].active_credit);

}