import React from 'react'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import Sidebar from '../Sidebar';
import Header from '../layout/Header';

const ExploreMarkets = () => {
    return (
        <Box className="Dashboard">
            <Header />
            <Grid container spacing={0} sx={{ textAlign: "center" }}>
                <Grid sm={12} md={12} xl={2} sx={{ pt:0 }}>
                    <Sidebar />
                </Grid>
                <Grid p={6} sm={12} md={12} xl={10} sx={{ boxShadow: 3 }}>
                    <h1>Explore Markets</h1>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ExploreMarkets;