import React from 'react'

import Box from '@mui/material/Box';

import Sidebar from '../Sidebar';
import Header from '../layout/Header';

const MyAccount = () => {
    var user_info = localStorage.getItem("user_info");
    user_info = JSON.parse(user_info);
    console.log("user_info", user_info);

    var user_mongodb = localStorage.getItem("user_mongodb");
    user_mongodb = JSON.parse(user_mongodb);
    console.log("user_mongodb", user_mongodb);

    return (
        <Box className="Dashboard">
            <Header />
            <div className='Dashboard--main'>
                <div className='Dashboard--wrap'>
                    <div className='Dashboard--inner'>
                        <div className='Dashboard--grid'>
                            <div className='Dashboard--item Dashboard--item--sidebar'>
                                <Sidebar />
                            </div>
                            <div className='Dashboard--item Dashboard--item--content'>
                                <h1>My Account</h1>
                                <p>Full Name: {user_info.name}</p>
                                <p>Email Address: {user_info.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default MyAccount;