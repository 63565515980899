import React, { useEffect, useState } from "react";
import VestmapLoader from './mongodb/VestmapLoader';

export default function VestmapLoaderList(props) {

  const Loader = () => (
    <VestmapLoader />
  );

  const [loaders, setLoaders] = useState([]);

  // This method fetches the loaders from the database.
  useEffect(() => {
    async function getLoaders() {

      let MongodbUserID = props.userId;
      console.log("VestmapLoaderList vestmap_loader userid", MongodbUserID);

      if(MongodbUserID) {

        let url = process.env.REACT_APP_NODE_URL + "/vestmap_loader?userid=" + MongodbUserID;
        console.log("VestmapLoaderList vestmap_loader get url", url);

        const response = await fetch( url );

        console.log("VestmapLoaderList vestmap_loader response", response);
  
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }
  
        const loaders = await response.json();  

        setLoaders(loaders);  

      }

    }

    getLoaders();

    return;
  }, [loaders.length]);
 
  // This method will delete a loader
  async function deleteLoader(id) {
    let url = process.env.REACT_APP_NODE_URL + `/vestmaps_loader/delete/${id}`;
    console.log("VestmapLoaderList vestmap_loader delete url", url);

    await fetch( url, { method: "DELETE" });
    const newLoaders = loaders.filter((el) => el._id !== id); setLoaders(newLoaders);
  }
 
  // This method will map out the loaders on the table
  function loaderList() {
    return loaders.map((loader) => {
      return (
        <Loader
          loader={loader}
          deleteLoader={() => deleteLoader(loader._id)}
          key={loader._id}
        />
      );
    });
  }
 
  // This following section will display the table with the loaders of individuals.
  return (
    <>
      {loaderList()}
    </>
  );
}
