import {React, useState, useEffect} from 'react'

import Box from '@mui/material/Box';

import Header from '../layout/Header';
import Sidebar from '../Sidebar';
import Content from '../Content';

const RunReports = () => {

    const [mongodbUser, setMongodbUser] = useState('');
    const [userId, setUserId] = useState('');
    const [activeCredit, setActiveCredit] = useState(0);
    const [usedCredit, setUsedCredit] = useState(0);

    useEffect(() => {
        const login_user_email = localStorage.getItem( "login_user_email" );
        console.log("RunReports login_user_email", login_user_email);
  
        if(login_user_email) {
            let url = process.env.REACT_APP_NODE_URL + '/user_data/search?email=' + login_user_email;
            console.log("RunReports user_data_search url", url);
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setMongodbUser(data);

                setUserId(data[0]._id);
                setActiveCredit(data[0].active_credit);
                setUsedCredit(data[0].used_credit);
                console.log("RunReports UserCredit App ID", data[0]._id);

                var mongodbUserJSON = JSON.stringify(data);
                localStorage.setItem("mongodbUser", mongodbUserJSON);
            })
            .catch((error) => console.log("RunReports UserCredit App Error", error) )  
        }
  
    }, [mongodbUser.length]);

    if(userId) {
        return (
            <Box className="Dashboard">
                <Header />
                <div className='Dashboard--main'>
                    <div className='Dashboard--wrap'>
                        <div className='Dashboard--inner'>
                            <div className='Dashboard--grid'>
                                <div className='Dashboard--item Dashboard--item--sidebar'>
                                    <Sidebar />
                                </div>
                                <div className='Dashboard--item Dashboard--item--content'>
                                    <Content userId={userId} activeCredit={activeCredit} usedCredit={usedCredit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        );    
    }
}

export default RunReports;