export default {
  oidc: {
    issuer: process.env.REACT_APP_OKTA_URL + "/oauth2/default",
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    scopes: ["openid", "profile", "email"],
    redirectUri: process.env.REACT_APP_OKTA_APP_URL + "/login/callback",
  },
  widget: {
    issuer: process.env.REACT_APP_OKTA_URL + "/oauth2/default",
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OKTA_APP_URL + "/login/callback",
    scopes: ["openid", "profile", "email"],
    useInteractionCodeFlow: true,
    pkce: true,
  },
};
