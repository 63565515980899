import React, { useEffect, useState } from "react";

import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

export default function VestmapList(props) {

  const Record = (props) => (
    <Grid sm={12} md={6}>
      <div className="DashboardVestmapsFlexBox">
        <div className="DashboardVestmapsImageBox">
          <img className="DashboardVestmapsImage" src={"https://vestmap.com/google_img/"+props.record.google_image_url} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = require('../img/defaultMap.png'); }} alt="Vestmap Defaul tMap" />
        </div>
        <div className="DashboardVestmapsContentBox" data-vestmapid={props.record._id}>
          <h3>{props.record.address_info.address}</h3>
          <a href={( props.record.pdf_url ) ? "https://vestmap.com/pdfs/"+props.record.pdf_url : '#'} target="_blank" rel="noreferrer">
            <Button endIcon={<LaunchIcon />} variant="contained" color="success" size="large" sx={{ "&:hover": { bgcolor: "#39585c" }, bgcolor: "#39585c", textTransform: 'capitalize' }}>View Report</Button>
          </a>
        </div>
      </div>
    </Grid>
  );

  const [records, setRecords] = useState([]);
  const [mongodbuser, setMongodbuser] = useState(props.userId);

  // This method fetches the records from the database.
  useEffect(() => {
    async function getRecords() {

      setMongodbuser(props.userId);
      console.log("VestmapList getRecords userid", mongodbuser);

      if(mongodbuser) {
        let url = process.env.REACT_APP_NODE_URL + "/vestmap?userid=" + mongodbuser;
        console.log("VestmapList vestmap url", url);
        const response = await fetch( url );

        console.log("VestmapList vestmap response", response);
  
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }
  
        const records = await response.json();
        setRecords(records);  
      }

    }

    getRecords();      
    console.log("VestmapList getRecords()");

    return;
  }, [records.length]);
 
  // This method will delete a record
  async function deleteRecord(id) {
    await fetch(`/${id}`, { method: "DELETE" });
    const newRecords = records.filter((el) => el._id !== id); setRecords(newRecords);
  }
 
  // This method will map out the records on the table
  function recordList() {
    return records.map((record) => {
      return (
        <Record
          record={record}
          deleteRecord={() => deleteRecord(record._id)}
          key={record._id}
        />
      );
    });
  }
 
  // This following section will display the table with the records of individuals.
  return (
    <>
      {recordList()}
    </>
  );
}
