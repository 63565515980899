import Grid from '@mui/material/Unstable_Grid2';

const addLoaderRecord = () => (
    <Grid sm={12} md={6}>
        <div className="DashboardVestmapsFlexBox">
            <div className="DashboardVestmapsImageBox">
                <img className="DashboardVestmapsImageLoader" src={require('../../img/loader.gif')} alt="Loader" />
                <img className="DashboardVestmapsImage" src={require('../../img/defaultMap.png')} alt="vestmap Default Map" />
            </div>
            <div className="DashboardVestmapsContentBox">
                <div className='DashboardVestmapsContentBoxTitle'>
                    <div className='DashboardVestmapsContentBoxTitle1'></div>
                    <div className='DashboardVestmapsContentBoxTitle2'></div>
                </div>
                <div className='DashboardVestmapsContentBoxProcess'>
                    <img className="DashboardVestmapsImageLoader" src={require('../../img/loader.gif')} alt="Loader" />
                    Processing PDF...
                </div>
            </div>
        </div>
    </Grid>
);

export default addLoaderRecord;