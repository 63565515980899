import { React } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignInWidget from './OktaSignInWidget';

const Login = ({ config }) => {
  
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {

    oktaAuth.handleLoginRedirect(tokens);
    var okta_token_storage = localStorage.getItem("okta-token-storage");
    okta_token_storage = JSON.parse(okta_token_storage);
    console.log("Login okta_token_storage", okta_token_storage);

    // Get email of login user
    let login_user_email;
         if( okta_token_storage.idToken.claims.email ) { login_user_email = okta_token_storage.idToken.claims.email; }
    else if( okta_token_storage.idToken.claims.preferred_username ) { login_user_email = okta_token_storage.idToken.claims.preferred_username; }
    localStorage.setItem( "login_user_email", login_user_email );
    console.log("Login login_user_email", login_user_email);

    // Get User From MongoDB
    let url = process.env.REACT_APP_NODE_URL + '/user_data/search?email=' + login_user_email;
    console.log("Login user_data_search url", url);
    
    fetch(url)
    .then((response) => response.json())
    .then((data) => {

      console.log("Login user_data search data", data);

      if(data.length > 0) {

        var user_mongodb = data;
        user_mongodb = JSON.stringify(user_mongodb);
        localStorage.setItem( "user_mongodb", user_mongodb );

      } else {

        url = process.env.REACT_APP_NODE_URL + `/stripe/customer/add?fullname=${okta_token_storage.idToken.claims.name}&email=${login_user_email}`;
        console.log("Login Stripe Customer Add url", url);

        fetch( url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then((response) => response.json())
        .then((data) => { console.log("Login Stripe Customer Add data", data); window.location.reload(true); })
        .catch((error) => { console.log("Login Stripe Customer Add error", error ); });
    
      }

    })
    .catch((error) => {
      console.log("Login user_data search error", error );
    });
    // Get User From MongoDB

  };

  const onError = (err) => {
    // Remove Login User Information
    console.log('Login Sign in error:', err);
  };

  if (!authState) {
    // Remove Login User Information
    return <div>Loading ... </div>;
  }

  if( authState.isAuthenticated ) {
    return <Redirect to={{ pathname: '/run-reports' }} />
  } else {
    return <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  }

};

export default Login;