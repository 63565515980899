import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

const Header = () => {

    const { oktaAuth, authState } = useOktaAuth();

    const history = useHistory();
  
    if (!authState) { return <div>Loading ...</div>; }
  
    const handleLogin = async () => history.push('/login');
  
    const handleLogout = async () => oktaAuth.signOut();

    const buttonStyle = { fontFamily: "'Roboto', sans-serif", fontStyle: 'normal', fontWeight: 700, fontSize: "24px", lineHeight: "20px", textTransform: 'capitalize', color: "#212529", p: 0, mr: "30px", background: "transparent", '&:hover': { background: "transparent", } };

    return (
        <header className='main_header'>
            <div className='main_header--wrap'>
                <div className='main_header--inner'>
                    <Grid container spacing={0}>
                        <Grid sm={12} md={6}>
                            <div>
                                <Link to="/run-reports">
                                    <Button sx={buttonStyle} variant="Text">Run reports</Button>
                                </Link>
                                <Link to="/explore-markets">
                                    <Button sx={buttonStyle} variant="Text">Explore Markets</Button>
                                </Link>
                            </div>
                        </Grid>
                        <Grid sm={12} md={6} sx={{textAlign: "right"}}>
                            <Link to="/myaccount">
                                <Button sx={buttonStyle} variant="text">My Account</Button>
                            </Link>
                            {
                                authState.isAuthenticated
                                ? <Button onClick={handleLogout} sx={buttonStyle} variant="text">Logout</Button>
                                : <Button onClick={handleLogin} sx={buttonStyle} variant="text">Login</Button>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </header>
    )
}

export default Header;